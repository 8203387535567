<ion-app
  [class.solakon-md]="getPlatform === 'android' || getPlatform === 'web'"
  [class.solakon-ios]="getPlatform === 'ios'">
  <!--<angular-query-devtools />-->

  <ion-router-outlet></ion-router-outlet>
  <ion-loading
    translucent="true"
    [isOpen]="helperService.isLoading()"
    [message]="helperService.loadingMessage()"
    spinner="crescent"></ion-loading>
</ion-app>
