import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { IonApp, IonLoading, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { TranslocoService } from '@jsverse/transloco';
import { HapticService } from '@services/haptic.service';
import { HelperService } from './services/helper.service';
import { SupabaseService } from './services/supabase.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonLoading, IonApp, IonRouterOutlet], // AngularQueryDevtools
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  getPlatform = Capacitor.getPlatform();

  @ViewChild(IonRouterOutlet) private routerOutlet!: IonRouterOutlet;

  constructor(
    public helperService: HelperService,
    private supabaseService: SupabaseService,
    private platform: Platform,
    private translocoService: TranslocoService,
    private hapticService: HapticService
  ) {}

  async ngAfterViewInit() {
    this.supabaseService.cacheUser();
  }

  async ngOnInit() {
    const language = localStorage.getItem('solakonapp-language');
    if (language) {
      this.translocoService.setActiveLang(language);
    }
    const enableHaptics = localStorage.getItem('solakonapp-enableHaptics');
    if (enableHaptics) {
      this.hapticService.enableHaptics.set(JSON.parse(enableHaptics));
    }

    this.translocoService.selectTranslation('de').subscribe();
    this.translocoService.selectTranslation('en').subscribe();

    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.addListener('keyboardWillShow', info => {
        this.alertPadding(info.keyboardHeight / 2);
      });

      Keyboard.addListener('keyboardWillHide', () => {
        this.alertPadding(0);
      });
    }

    if (Capacitor.getPlatform() === 'android') {
      this.platform.backButton.subscribeWithPriority(-1, () => {
        if (!this.routerOutlet.canGoBack()) {
          App.exitApp();
        }
      });
    }
  }

  async ngOnDestroy() {
    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.removeAllListeners();
    }
  }

  alertPadding(padding: number) {
    const ionAlert = document.querySelector('ion-alert');
    if (ionAlert) {
      ionAlert.style.transform = 'translateY(-' + padding.toString() + 'px)';
    }
  }
}
